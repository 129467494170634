import Button from "./../../styles/components/Button.style";
import Modal from "./../../styles/components/Modal.style";

import { extendTheme } from "@chakra-ui/react";

import tokens from "./../../styles/tokens";

const theme = extendTheme({
    components: {
        Button,
        Modal,
    },
    colors: {
        ...tokens.colors,
    },
});

export default theme;
export const baseTheme = {};
