import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Header from "../Header";
import Footer from "../Footer";

import { SkipNavLink, SkipNavContent } from "@chakra-ui/skip-nav";

import { Container, Box } from "@chakra-ui/react";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <>
            <React.StrictMode>
                <SkipNavLink>Skip to content</SkipNavLink>
                <Header />
                <Box>
                    <SkipNavContent />
                    <Container px={0} maxW="full">
                        {children}
                    </Container>
                </Box>
                <Footer currentYear={new Date().getFullYear()} />
            </React.StrictMode>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
