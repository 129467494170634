import React from "react";

import { defineStyleConfig } from "@chakra-ui/react";

import tokens from "./../tokens";

const Modal = defineStyleConfig({
    baseStyle: {
        closeButton: {
            borderRadius: "full",
            zIndex: "9999 !important",
            bg: "transparent",
            border: "2px",
            borderColor: "white",
            marginTop: "10px",
            marginRight: "16px",
            width: "40px",
            height: "40px",
            color: "white",
            _hover: {
                bg: "white",
                color: "black",
            },
        },
        dialog: {
            overflow: "clip",
        },
        body: {
            overflow: "clip",
        },
    },
    sizes: {
        base: {
            dialog: {
                minWidth: "full",
            },
            dialogContainer: {
                paddingX: ".75rem",
            },
        },

        sm: {
            dialog: {
                minWidth: "full",
            },
            dialogContainer: {
                paddingX: "1rem",
            },
        },
        md: {
            dialog: {
                minWidth: "full",
            },
            dialogContainer: {
                paddingX: "2rem",
            },
        },
        lg: {
            dialog: {
                maxWidth: "960px",
            },
            dialogContainer: {
                paddingX: "2rem",
            },
        },
        xl: {
            dialog: {
                maxWidth: "1280px",
            },
            dialogContainer: {
                paddingX: "3rem",
            },
        },
    },
});

export default Modal;
