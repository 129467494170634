import React from "react";
import * as ReactDOM from "react-dom/client";
import { defineStyleConfig } from "@chakra-ui/react";

import tokens from "./../tokens";

const Button = defineStyleConfig({
    // The styles all button have in common
    baseStyle: {
        fontWeight: "bold",
        borderRadius: "4px",
        lineHeight: "normal",
    },
    // Two sizes: sm and md
    sizes: {
        base: {
            fontSize: "md",
            px: 7, // <-- px is short for paddingLeft and paddingRight
            py: 4, // <-- py is short for paddingTop and paddingBottom
        },
        sm: {
            fontSize: "sm",
            px: 5, // <-- px is short for paddingLeft and paddingRight
            py: 3, // <-- py is short for paddingTop and paddingBottom
        },
        md: {
            fontSize: "md",
            px: 7, // <-- these values are tokens from the design system
            py: 6, // <-- these values are tokens from the design system
        },
        lg: {
            fontSize: "md",
            px: 7, // <-- these values are tokens from the design system
            py: 5, // <-- these values are tokens from the design system
        },
    },
    // Two variants: outline and solid
    variants: {
        outline: {
            fontWeight: "medium",
            border: "2px solid",
            borderColor: "gray.300",
            borderRadius: "full",
            py: 4,
            px: 6,
            color: "gray.600",
            _hover: {
                bg: "transparent",
                color: "black",
                borderColor: "gray.700",
            },
            _active: {
                bg: "transparent",
            },
            _focus: {
                outline: "none",
                color: "black",
                borderColor: "gray.700",
                boxShadow: `0 0 0 3px rgba(15, 151, 138, 0.7)`,
            },
        },
        primary: {
            bg: tokens.colors.medico.medicoGreen,
            color: "white",
            borderRadius: "full",
            _hover: {
                bg: tokens.colors.medico.medicoGreenDark,
            },
            _active: {
                outline: "none",
                boxShadow: `inset 0 0 0 2px rgba(255, 255, 255, 1)`,
            },
            _focus: {
                outline: "none",
                boxShadow: `inset 0 0 0 2px rgba(255, 255, 255, 1)`,
            },
        },
        download: {
            bg: "black",
            color: "white",
            py: 0,
            outline: "none",
            textDecoration: "none",
            _hover: {
                textDecoration: "none",
                color: "white",
                bg: tokens.colors.medico.medicoGreenDark,
            },
            _active: {
                color: "white",
                outline: "none",
                boxShadow: `0 0 0 3px ${tokens.colors.medico.medicoGreen}`,
            },
            _focus: {
                color: "white",
                outline: "none",
                boxShadow: `0 0 0 3px ${tokens.colors.medico.medicoGreen}`,
            },
            _visited: {
                color: "white",
            },
        },
    },
    // The default size and variant values
    defaultProps: {
        size: "md",
        variant: "primary",
    },
});

export default Button;
