import React from "react";
import Layout from "./components/Layout";

import { Global, css } from "@emotion/react";

import tokens from "./styles/tokens";
const breakpoints = {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
};

export const wrapPageElement = ({ element }) => {
    return (
        <>
            <Global
                styles={css`
                    html, body {
                        background: ${tokens.colors.medico.medicoGreenDark};
                    },
                    body {
                        font-faamily: 'Inter', sans-serif;
                        fontSize: 18px;
                    },
                    p.chakra-text a {
                        text-decoration: underline;
                        text-decoration-thickness: 2px;
                        text-underline-offset: 5px;
                        text-decoration-color: ${tokens.colors.medico.medicoGreen};
                        &:hover, &:active, &:focus {
                            color: ${tokens.colors.medico.medicoGreen};
                            text-decoration-color:
                                ${tokens.colors.medico.medicoGreen};
                            text-decoration-thickness: 2px;
                        },
                    }
                `}
            />
            <Layout>{element}</Layout>
        </>
    );
};
