import React from "react";

import "./styles.module.css";

import {
    Container,
    Box,
    VisuallyHidden,
    Stack,
    Text,
    Link,
    Spacer,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

type FooterProps = {
    currentYear?: number;
};

const Footer = ({ currentYear }: FooterProps) => (
    <Box bg="medico.medicoBlueDark">
        <Container
            pt={16}
            pb={{ base: 0, lg: 24 }}
            px={{ base: 6, md: 4 }}
            maxW={{
                base: "full",
                md: "container.md",
                lg: "container.lg",
                xl: "container.xl",
            }}
            as="footer"
        >
            <Box mb={10}>
                <StaticImage
                    alt="Mariners Medico Guide Logo"
                    placeholder="blurred"
                    width={88}
                    src="../../assets/medico_app_icon.svg"
                    quality={90}
                />
            </Box>
            <Stack
                w="full"
                direction={{ base: "column", lg: "row" }}
                spacing="24px"
            >
                <Box
                    w="full"
                    textAlign={{ base: "left", md: "left" }}
                    mb={{ base: 8, lg: 0 }}
                >
                    <Text fontSize="md" fontWeight="normal" color="white">
                        Please tell us if you have feedback or questions
                        concerning the app, its content, its functionality or
                        other issues.
                        <br />
                        <br />
                        We can be contacted at{" "}
                        <a
                            href="mailto:support@medicoguide.com"
                            title="Mariners Medico Guide support email"
                        >
                            {" "}
                            sup<VisuallyHidden>xyz</VisuallyHidden>
                            port&#064;medico<VisuallyHidden>xyz</VisuallyHidden>
                            guide.com
                        </a>
                    </Text>
                </Box>
                <Box w="full" textAlign={{ base: "left", lg: "right" }}>
                    <Text
                        mb={14}
                        fontSize="sm"
                        fontWeight="normal"
                        color="white"
                    >
                        Copyright &copy; {currentYear}, Mariners Medico Guide
                    </Text>
                </Box>
            </Stack>
        </Container>
    </Box>
);

export default Footer;
