const tokens = {
    colors: {
        medico: {
            medicoGreen: "rgba(15, 151, 138, 1)",
            medicoGreenDark: "rgba(19, 48, 61, 1)",
            medicoBlue: "rgba(65, 143, 176, 1)",
            medicoBlueDark: "rgba(36, 78, 97, 1)",
            medicoGray: "#F1F4F6"
        }
    }
};

export default tokens;