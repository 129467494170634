import * as React from "react";
import { Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";

import { CloseIcon } from "@chakra-ui/icons";

import {
    List,
    ListItem,
    Link,
    Container,
    HStack,
    StackItem,
    Button,
    Box,
    Collapse,
    Icon,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";

const Navigation = () => {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const {
        isOpen: navIsOpen,
        onOpen: navOnOpen,
        onClose: navOnClose,
    } = useDisclosure();
    const { isOpen: langIsOpen, onToggle: langOnToggle } = useDisclosure();

    return (
        <>
            <Container
                as="nav"
                shadow={{ base: "sm", md: "none" }}
                py={4}
                px={{ base: 6, lg: 6, md: 4 }}
                maxW={{
                    base: "full",
                    md: "container.md",
                    lg: "container.lg",
                    xl: "container.xl",
                }}
            >
                <HStack justifyContent="space-between" w="full" spacing="24px">
                    <StackItem>
                        <Link as={GatsbyLink} to="/">
                            <StaticImage
                                src="../../assets/medico_top_logo.png"
                                alt="Mariners Medico Guide Logo"
                                layout="constrained"
                                width={130}
                                placeholder="blurred"
                            />
                        </Link>
                    </StackItem>
                    <StackItem
                        as={List}
                        gap={5}
                        display={{ base: "none", md: "flex" }}
                    >
                        <ListItem>
                            <Button
                                as={GatsbyLink}
                                variant="outline"
                                to="/about"
                            >
                                About
                            </Button>
                        </ListItem>
                        {/* Insert language dropdown for desktop here */}
                    </StackItem>
                    <StackItem display={{ md: "none" }}>
                        <IconButton
                            size={"lg"}
                            color="medico.medicoGreen"
                            _hover={{
                                bg: "medico.medicoGray",
                            }}
                            icon={
                                navIsOpen ? <CloseIcon /> : <Icon as={FaBars} />
                            }
                            aria-label={"Open Menu"}
                            onClick={navIsOpen ? navOnClose : navOnOpen}
                            variant="ghost"
                        />
                    </StackItem>
                </HStack>
            </Container>
            <Collapse in={navIsOpen} animateOpacity>
                <Container
                    as={Flex}
                    px={{ base: 5, md: 4 }}
                    justifyContent="center"
                    maxWidth="full"
                    bg="gray.100"
                >
                    <Box
                        py={5}
                        w="100%"
                        maxW={{
                            base: "full",
                            md: "container.md",
                            lg: "container.lg",
                            xl: "container.xl",
                        }}
                    >
                        <List
                            as={Box}
                            py={2}
                            bg="white"
                            rounded="md"
                            shadow="sm"
                        >
                            <ListItem
                                rounded="md"
                                mx={2}
                                textDecoration="none"
                                css={{
                                    textDecoration: "none",
                                }}
                                _hover={{
                                    textDecoration: "none",
                                    bg: "rgba(15, 151, 138, .15)",
                                }}
                                _active={{
                                    bg: "rgba(15, 151, 138, .15)",
                                    textDecoration: "none",
                                    color: "black",
                                    fontWeight: "medium",
                                }}
                            >
                                <Link
                                    display="block"
                                    as={GatsbyLink}
                                    to="/about"
                                    onClick={navOnClose}
                                    px={3}
                                    py={3}
                                >
                                    About
                                </Link>
                            </ListItem>
                            {/* Insert language dropdown for mobile here */}
                        </List>
                    </Box>
                </Container>
            </Collapse>
        </>
    );
};

export default Navigation;
