import * as React from "react";
import Navigation from "../Navigation";

import { Container, Box, Flex } from "@chakra-ui/react";

const Header = () => (
    <Box as="header" bg="white">
        <Container as={Flex} p={0} maxW="full" direction="column">
            <Navigation />
        </Container>
    </Box>
);

export default Header;
